<template>
  <div class="main-municipality">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">
          Título, Diretriz, Comissões e Eixos
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="e1 > 2" step="2">
          Fases e Limites de Delegados
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-form
            @submit="submitForm"
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <v-card class="mb-12">
              <div class="responsibility">
                <span>Cadastro de Nova Conferência</span>
              </div>
            </v-card>

            <div style="display: flex">
              <v-img
                style="background-color: grey"
                type="image"
                max-width="300"
                max-height="300"
                :src="imageUrl"
              ></v-img>
              <div class="ml-5">
                <v-text-field
                  v-model="title"
                  :counter="100"
                  :rules="nameRules"
                  label="Titulo"
                  style="width: 700px"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="theme"
                  :counter="100"
                  :rules="nameRules"
                  label="Tema"
                  required
                ></v-text-field>
                <v-select
                  v-model="select"
                  :items="items"
                  :rules="[(v) => !!v || 'Vigência é obrigatório!']"
                  label="Vigência"
                  required
                ></v-select>
                <v-file-input
                  accept="image/*"
                  label="Adicione uma imagem"
                  v-model="image"
                  :rules="nameEixos"
                  @change="onFileChange"
                ></v-file-input>
                <v-checkbox
                  v-model="checkboxDiretriz"
                  label="Possui Diretrizes?"
                  disabled
                ></v-checkbox>
              </div>
            </div>

            <div class="responsibility">
              <span>Comissão/Função</span>
              <div class="line-title"></div>
            </div>

            <v-row>
              <v-col cols="12" md="2">
                <label for="quantity"></label>
                <v-text-field
                  disabled
                  id="quantity"
                  style="margin-top: -8px"
                  type="number"
                  :rules="numEixos"
                  min="0"
                  max="10"
                  label="Quantidade"
                  placeholder="Quantidade"
                  v-model="numCommissionFields"
                />
              </v-col>
              <v-col cols="12" md="8">
                <div
                  class="input-container"
                  v-for="(input, index) in commissionFields"
                  :key="index"
                >
                  <div class="title-eixos eixos-news">
                    <label>0{{ index + 1 }}.</label>
                    <v-text-field
                      disabled
                      type="text"
                      name="eixo"
                      :rules="nameEixos"
                      v-model="input.name"
                      min="0"
                      :class="['input-' + (index + 1) + ' ' + 'my-input']"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>

            <div class="inputs-eixos">
              <div class="eixos-inputs"></div>
              <span class="line-title"></span>
            </div>
            <div class="responsibility">
              <span>Eixos e Sub-Eixos</span>
              <div class="line-title"></div>
            </div>

            <v-row>
              <v-col cols="12" md="2">
                <label for="quantity"></label>
                <v-text-field
                  disabled
                  style="margin-top: -8px"
                  placeholder="Quantidade"
                  label="Quantidade"
                  id="quantity"
                  type="number"
                  :rules="numEixos"
                  min="0"
                  max="10"
                  v-model="numInputs"
                />
              </v-col>
              <v-col cols="12" md="10">
                <div v-for="(input, index) in inputs" :key="index">
                  <div class="title-eixos eixos-news">
                    <label>0{{ index + 1 }}</label>
                    <v-text-field                      
                      type="text"
                      name="eixo"
                      :rules="nameEixos"
                      v-model="input.title"
                      min="0"
                      :class="['input-' + (index + 1) + ' ' + 'my-input']"
                    />
                  </div>

                  <v-row>
                    <v-col cols="10" md="3">
                      <div
                        style="
                          display: flex;
                          align-items: center;
                          justify-content: start;
                        "
                      >
                        <div>
                          <v-text-field
                            disabled
                            label="Sub-Eixos"
                            type="number"
                            :rules="subEixo"
                            min="0"
                            max="10"
                            v-model="input.sub_axie.length"
                          />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="10" md="7">
                      <div
                        class="title-eixos eixos-news"
                        v-for="(adicional, i) in input.sub_axie"
                        :key="i"
                      >
                        <label>{{ index + 1 }}.{{ i + 1 }}:</label>
                        <v-text-field                          
                          type="text"
                          min="0"
                          :rules="nameEixos"
                          max="10"
                          v-model="adicional.title"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <div style="display: flex; justify-content: end">
              <v-btn color="primary" @click="nextStep"> Continuar </v-btn>
            </div>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-form
            @submit="submitForm"
            ref="form2"
            v-model="valid"
            lazy-validation
          >
            <v-card class="mb-12">
              <div class="region">
                <div class="line-title"></div>
                <div>
                  <v-checkbox
                    v-model="checkboxMuni"
                    label="Possui Fase Municipal?"
                    disabled
                    required
                  ></v-checkbox>

                  <!-- v-if="checkboxMuni" -->
                  <div class="division" v-if="checkboxMuni">
                    <div class="responsibility">
                      <span>Fase Municipal</span>
                      <div class="line-title"></div>
                    </div>

                    <div class="grid-container-flex">
                      <div class="column-flex-template">
                        <h4>Prazo de lançamento</h4>
                        <label class="label-municipal" for="date-initial">
                          <v-text-field
                            v-model="dateInitialMunicipal"
                            type="date"
                            :rules="dateRules"
                            label="Data de Início"
                            required
                          ></v-text-field>
                        </label>
                        <label class="label-municipal" for="date-end">
                          <v-text-field
                            v-model="dateEndMunicipal"
                            :min="dateInitialMunicipal"
                            :rules="dateRules"
                            type="date"
                            label="Data de Fim"
                            required
                          ></v-text-field>
                        </label>
                        <label for="">
                          <h4>Limite de propostas</h4>
                          <v-select
                            disabled
                            v-model="selectMunicipal"
                            :items="selectMunicipalPro"
                            :rules="dateRules"
                            item-text="name"
                            item-value="id"
                            label="Tipo de limite"
                            required
                          ></v-select>
                          <v-text-field
                            disabled
                            v-model="proposalMunicipal"
                            type="number"
                            :rules="numberMunicipal"
                            label="Quantidade"
                            required
                          ></v-text-field>
                        </label>
                      </div>
                      <!-- ANCHOR - 'Para até' HTML -->
                      <div>
                        <div class="responsibility">
                          <span>Limite de Delegados por Município</span>
                        </div>

                        <v-row>
                          <v-col cols="12" md="2">
                            <v-text-field
                              disabled
                              id="quantity"
                              v-model="numCountyDelegate"
                              :rules="numberMunicipal"
                              label="Faixas"
                              type="number"
                              min="2"
                              max="10"
                              v-text-field
                            />
                          </v-col>
                          <v-col cols="12" md="10">
                            <v-row>
                              <v-col
                                class="row-flex-template"
                                v-for="(input, index) in inputsCountyDelegates"
                                :key="index"
                              >
                                <label
                                  class="label-delegate"
                                  for="for-to"
                                  v-if="index == 0"
                                >
                                  <span class="mr-4">0{{ index + 1 }}.</span>
                                  <v-text-field
                                    disabled
                                    v-model="initalValueDelegate"
                                  ></v-text-field>
                                  à
                                </label>
                                <label
                                  class="label-delegate"
                                  for="for-to"
                                  v-if="
                                    index != 0 &&
                                    index != inputsCountyDelegates.length - 1
                                  "
                                >
                                  <span class="mr-4">0{{ index + 1 }}.</span>
                                  <v-text-field
                                    disabled
                                    v-model="
                                      inputsCountyDelegates[index - 1]
                                        .population_number
                                    "
                                  ></v-text-field>
                                  à
                                </label>

                                <label
                                  class="label-delegate"
                                  for="for-to"
                                  v-if="
                                    index == inputsCountyDelegates.length - 1 &&
                                    index != 0
                                  "
                                >
                                  <span class="mr-4">0{{ index + 1 }}.</span>
                                  <span
                                    :v-model="
                                      (input.population_number =
                                        inputsCountyDelegates[
                                          index - 1
                                        ].population_number)
                                    "
                                    style="white-space: nowrap"
                                    >Acima de
                                  </span>
                                </label>

                                <label class="label-delegate" for="for-to">
                                  <v-text-field
                                    disabled
                                    v-model="input.population_number"
                                    min="0"
                                    type="number"
                                    :rules="numberZero"
                                    required
                                  ></v-text-field>
                                  <span class="mr-4">habitantes</span>
                                </label>
                                <label class="label-delegate" for="population">
                                  <v-text-field
                                    disabled
                                    class="mr-3"
                                    label="Titulares"
                                    v-model="input.delegate_number"
                                    min="0"
                                    type="number"
                                    :rules="numberZero"
                                    required
                                  ></v-text-field>
                                </label>
                                <label class="label-delegate" for="population">
                                  <v-text-field
                                    disabled
                                    label="Suplentes"
                                    v-model="input.substitutes"
                                    min="0"
                                    type="number"
                                    :rules="numberZero"
                                    required
                                  ></v-text-field>
                                </label>
                              </v-col>
                            </v-row>
                          </v-col>
                          <div>
                            <div class="responsibility">
                              <span
                                >Limite de Delegados Conferências Livres</span
                              >
                            </div>
                            <v-row>
                              <v-col cols="12" md="2">
                                <v-text-field
                                  disabled
                                  id="quantity"
                                  v-model="numInstituteDelegate"
                                  :rules="numberMunicipal"
                                  label="Faixas"
                                  type="number"
                                  min="2"
                                  max="10"
                                  v-text-field
                                />
                              </v-col>
                              <v-col cols="12" md="10">
                                <v-row>
                                  <v-col
                                    class="row-flex-template"
                                    v-for="(
                                      input, index
                                    ) in inputsInstituteDelegates"
                                    :key="index"
                                  >
                                    <label
                                      class="label-delegate"
                                      for="for-to"
                                      v-if="index == 0"
                                    >
                                      <span class="mr-4"
                                        >0{{ index + 1 }}.</span
                                      >
                                      <v-text-field
                                        disabled
                                        v-model="initalValueDelegate"
                                      ></v-text-field>
                                      à
                                    </label>
                                    <label
                                      class="label-delegate"
                                      for="for-to"
                                      v-if="
                                        index != 0 &&
                                        index !=
                                          inputsInstituteDelegates.length - 1
                                      "
                                    >
                                      <span class="mr-4"
                                        >0{{ index + 1 }}.</span
                                      >
                                      <v-text-field
                                        disabled
                                        v-model="
                                          inputsInstituteDelegates[index - 1]
                                            .participants_number
                                        "
                                      ></v-text-field>
                                      à
                                    </label>

                                    <label
                                      class="label-delegate"
                                      for="for-to"
                                      v-if="
                                        index ==
                                          inputsInstituteDelegates.length - 1 &&
                                        index != 0
                                      "
                                    >
                                      <span class="mr-4"
                                        >0{{ index + 1 }}.</span
                                      >
                                      <span
                                        :v-model="
                                          (input.participants_number =
                                            inputsInstituteDelegates[
                                              index - 1
                                            ].participants_number)
                                        "
                                        style="white-space: nowrap"
                                        >Acima de
                                      </span>
                                    </label>

                                    <label class="label-delegate" for="for-to">
                                      <v-text-field
                                        disabled
                                        v-model="input.participants_number"
                                        min="0"
                                        type="number"
                                        :rules="numberZero"
                                        required
                                      ></v-text-field>
                                      <span class="mr-4">participantes</span>
                                    </label>
                                    <label
                                      class="label-delegate"
                                      for="population"
                                    >
                                      <v-text-field
                                        disabled
                                        class="mr-3"
                                        label="Titulares"
                                        v-model="input.delegate_number"
                                        min="0"
                                        type="number"
                                        :rules="numberZero"
                                        required
                                      ></v-text-field>
                                    </label>
                                    <label
                                      class="label-delegate"
                                      for="population"
                                    >
                                      <v-text-field
                                        disabled
                                        label="Suplentes"
                                        v-model="input.substitutes"
                                        min="0"
                                        type="number"
                                        :rules="numberZero"
                                        required
                                      ></v-text-field>
                                    </label>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </div>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="line-title"></span>

                <div>
                  <v-checkbox
                    v-model="checkboxMacro"
                    label="Possui Fase Macrorregional?"
                    disabled
                    required
                  ></v-checkbox>

                  <div class="division" v-if="checkboxMacro">
                    <div class="responsibility">
                      <span>Fase Macrorregional</span>
                      <div class="line-title"></div>
                    </div>

                    <div class="grid-container-flex">
                      <div class="column-flex-template">
                        <h4>Prazo de lançamento</h4>
                        <label class="label-municipal" for="date-initial">
                          <v-text-field
                            v-model="dateInitialMacro"
                            type="date"
                            :min="dateEndMunicipal"
                            :rules="dateRules"
                            label="Data de Início"
                            required
                          ></v-text-field>
                        </label>
                        <label class="label-municipal" for="date-end">
                          <v-text-field
                            v-model="dateEndMacro"
                            type="date"
                            :min="dateInitialMacro"
                            :rules="dateRules"
                            label="Data de Fim"
                            required
                          ></v-text-field>
                        </label>
                        <h4>Limite de Propostas</h4>
                        <v-select
                          disabled
                          v-model="selectMacro"
                          :items="selectMacroPro"
                          :rules="dateRules"
                          item-text="name"
                          item-value="id"
                          label="Tipo de Limite"
                          required
                        ></v-select>
                        <v-text-field
                          disabled
                          v-model="proposalMacro"
                          type="number"
                          :rules="numberMunicipal"
                          label="Quantidade"
                          required
                        ></v-text-field>
                      </div>
                      <!-- ANCHOR - 'Para até' HTML -->
                      <div>
                        <div class="responsibility-t">
                          <span>Quantidade de Delegados por Macro</span>
                        </div>

                        <v-row>
                          <v-col
                            cols="12"
                            md="12"
                            class="row-flex-template-macro"
                            v-for="(
                              input, index
                            ) in inputsRegionalNucleusDelegates"
                            :key="index"
                          >
                            <div class="grid-delegate">
                              <label
                                class="label-delegate label-macro"
                                for="for-to"
                              >
                                0{{ index + 1 }}.
                                <v-text-field
                                  :value="input.regional_nucleus_health.name"
                                  min="0"
                                  type="text"
                                  disabled
                                  required
                                ></v-text-field>
                              </label>

                              <label class="label-delegate" for="population">
                                <v-text-field
                                  disabled
                                  v-model="input.delegate_number"
                                  class="mr-3"
                                  label="Titulares"
                                  min="0"
                                  type="number"
                                  :rules="numberZero"
                                  required
                                ></v-text-field>
                              </label>
                              <label class="label-delegate" for="population">
                                <v-text-field
                                  disabled
                                  v-model="input.substitutes"
                                  label="Suplentes"
                                  min="0"
                                  type="number"
                                  :rules="numberZero"
                                  required
                                ></v-text-field>
                              </label>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="line-title"></span>
              </div>
              <div class="region">
                <div class="division">
                  <div class="responsibility">
                    <span>Fase Estadual</span>
                    <div class="line-title"></div>
                  </div>

                  <div class="grid-container-flex">
                    <div class="column-flex-template">
                      <h4>Prazo de lançamento</h4>

                      <label class="label-municipal">
                        <v-text-field
                          v-model="dateInitialEstadual"
                          type="date"
                          :min="dateEndMacro || dateEndMunicipal"
                          :rules="dateRules"
                          label="Data de Início"
                          required
                        ></v-text-field>
                      </label>

                      <label class="label-municipal">
                        <v-text-field
                          v-model="dateEndlEstadual11"
                          :min="dateInitialEstadual"
                          type="date"
                          :rules="dateRules"
                          label="Data de Fim"
                          required
                        ></v-text-field>
                      </label>
                      <h4>Limite de Propostas</h4>
                      <v-select
                        disabled
                        v-model="selectEstadual"
                        :items="selecEstadualPro"
                        :rules="dateRules"
                        item-value="id"
                        item-text="name"
                        label="Tipo de Limite"
                        required
                      ></v-select>

                      <v-text-field
                        disabled
                        v-model="proposalEstadual"
                        type="number"
                        :rules="numberMunicipal"
                        label="Quantidade"
                        required
                      ></v-text-field>
                    </div>
                    <v-row class="ml-5">
                      <v-col cols="12" md="12">
                        <div class="responsibility">
                          <span
                            >Limitação de Delegados Eleitos para Fase
                            Nacional</span
                          >
                        </div>
                        <div class="grid-delegate space-button">
                          <label class="label-delegate" for="for-to">
                            Delegados titulares
                            <v-text-field
                              disabled
                              v-model="inputDelegate"
                              min="0"
                              type="number"
                              :rules="numberMunicipal"
                              required
                            ></v-text-field>
                          </label>

                          <label class="label-delegate" for="population">
                            Delegados Suplentes
                            <v-text-field
                              disabled
                              v-model="inputDelegate1"
                              min="0"
                              type="number"
                              :rules="numberMunicipal"
                              required
                            ></v-text-field>
                          </label>
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>

              <v-snackbar v-model="snackbar">
                {{ message }}
                <template v-slot:action="{ attrs }">
                  <v-btn
                    color="pink"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Fechar
                  </v-btn>
                </template>
              </v-snackbar>
            </v-card>

            <div style="display: flex; justify-content: end">
              <v-btn color="primary" @click="e1 = 1" class="mr-1">
                Voltar
              </v-btn>
              <v-btn color="success" @click="submitForm"> Atualizar </v-btn>
            </div>
          </v-form>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import axios from "@/axios";

export default {
  data: () => ({
    e1: 1,
    dialog: false,
    dialogDelete: false,
    errorLogin: [],
    valid: true,
    theme: "",
    title: "",
    numFields: 0,
    textFields: [],
    imageUrl: null,
    archive_cover_id: null,
    displayError: {
      display: false,
      message: [],
    },

    headersEixos: [
      { text: "Titulo", value: "title" },
      { text: "Ações", value: "actions", sortable: false },
    ],

    // ANCHOR - Váriaveis principais
    // #region
    dateInitialEstadual: "",
    dateEndlEstadual11: "",
    dateEndlEstadual: "",
    proposalEstadual: "",

    proposalMunicipal: "",
    dateInitialMunicipal: "",
    immutableDateInitialMunicipal: "",
    dateEndMunicipal: "",

    dateInitialMacro: "",
    dateEndMacro: "",
    proposalMacro: "",

    image: null,

    inputDelegate: null,
    inputDelegate1: null,
    newInputs: [],

    lineNewColumns: 0,
    lineNewColumns2: 0,
    lineNewColumns3: 0,

    // Variavéis de comissão/função
    numCommissionFields: 0,
    commissionFields: [],

    numInputs: 0,
    inputs: [],

    numCountyDelegate: 0,
    inputsCountyDelegates: [],
    initalValueDelegate: 0,
    numInstituteDelegate: 0,
    inputsInstituteDelegates: [],
    checkboxMuni: false,
    checkboxMacro: false,
    btnStatus: "Acessar",
    carregamento: false,
    desativado: false,
    checkboxDiretriz: false,

    items: ["Ativado", "Desativado"],

    //Select - Macro - Municipal - Estadual
    selectMunicipalPro: [],
    selectMunicipal: null,

    selectMacroPro: [],
    selectMacro: null,

    selecEstadualPro: [],
    selectEstadual: null,

    // ANCHOR - Regras

    nameRules: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v.length >= 3) || "O nome deve ter no mínimo 3 caracteres.",
    ],

    numberZero: [
      (v) => !!v || "Campo obrigatório!",
      (v) => v >= 0 || "O número é abaixo do esperado",
    ],

    nameEixos: [(v) => !!v || "Campo obrigatório!"],

    subEixo: [(v) => v <= 10 || "O número é acima do esperado"],

    numberMunicipal: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v > 0) || "O número é abaixo do esperado",
    ],

    numEixos: [
      (v) => !!v || "Campo obrigatório!",
      (v) => (v && v > 0) || "O número é abaixo do esperado",
      (v) => (v && v <= 10) || "O número é acima do esperado",
    ],

    firstMni: [
      (v) => !!v || "Campo obrigatório!",
      (v) =>
        (v && v < this.lineOneMunicipal) || "O número é a baixo do esperado.",
    ],
    dateRules: [(v) => !!v || "Campo obrigatório!"],
    dateRulesMunicipal: [
      (v) =>
        (!!v && new Date(v) >= new Date(this.dateInitialMunicipal)) ||
        "A data precisa proceder da data de inicio",
    ],

    select: null,
    errorConfe: "",
    message: "",
    snackbar: false,

    formData: {
      conference: {
        title: null,
        deleted_at: null,
        conference_theme: null,
        initial_county_phase_date: null,
        final_county_phase_date: null,
        initial_macro_phase_date: null,
        final_macro_phase_date: null,
        initial_state_phase_date: null,
        final_state_phase_date: null,
      },
      axie: [],
      roles: [],
      rules: [],
    },
    // #endregion
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    this.getSelection();
    this.getConference();
  },

  methods: {
    getSelection() {
      axios.get("/conference-proposal-standard").then((res) => {
        this.selectMunicipalPro = res.data;
        this.selectMacroPro = res.data;
        this.selecEstadualPro = res.data;
      });
    },
    onFileChange() {
      // Transformando IMG -> Blob
      this.imageUrl = URL.createObjectURL(
        new Blob([this.image], { type: "image/jpeg" })
      );
      let formData = new FormData();
      formData.append("archive", this.image);
      formData.append("archive_type_id", 2);
      axios.post(`archives/${this.archive_cover_id}`, formData).then(() => {
        this.$swal({
          icon: "success",
          title: "Arquivo atualizado com sucesso!",
        });
      });
    },
    nextStep() {
      if (this.$refs.form.validate() == true) {
        this.e1 = 2;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },

    filterArchiveByType(archives, archive_type_id) {
      return archives.filter(
        (archive) => archive.archive_type_id == archive_type_id
      );
    },

    getConference() {
      // ANCHOR - Dados da página
      axios.get(`conference/${this.$route.query.id}`).then((res) => {
        this.inputs = res.data.axie;
        this.archive_cover_id = res.data.archive_cover[0].id;
        axios
          .post(
            "/archives/download",
            { archive_id: res.data.archive_cover[0].id },
            { responseType: "blob" }
          )
          .then((response) => {
            this.imageUrl = URL.createObjectURL(
              new Blob([response.data], { type: "image/jpeg" })
            );
          });

        //Pegando todos os dados para apenas exibição em tela
        this.theme = res.data.conference_theme;
        this.title = res.data.title;

        this.image = res.data.archive_cover[0].archive_name;
        if (res.data.initial_county_phase_date) {
          this.checkboxMuni = true;
        }

        if (res.data.initial_macro_phase_date) {
          this.checkboxMacro = true;
        }

        //proposalEstadual
        this.dateInitialMunicipal = !res.data.initial_county_phase_date
          ? ""
          : res.data.initial_county_phase_date.slice(0, 10);
        this.dateEndMunicipal = !res.data.final_county_phase_date
          ? ""
          : res.data.final_county_phase_date.slice(0, 10);
        this.proposalMunicipal = res.data.max_proposal_number_county_phase;
        this.proposalMacro = res.data.max_proposal_number_macro_phase;

        this.selectMunicipal = Number(
          res.data.conference_proposal_county_standard_id
        );
        this.selectMacro = Number(
          res.data.conference_proposal_macro_standard_id
        );
        this.selectEstadual = Number(
          res.data.conference_proposal_state_standard_id
        );

        this.select = res.data.deleted_at ? "Desativado" : "Ativado";

        this.checkboxDiretriz = res.data.hasguidelines;

        //Data inico e fim da Macro
        this.dateEndMacro = !res.data.final_macro_phase_date
          ? ""
          : res.data.final_macro_phase_date.slice(0, 10);
        this.dateInitialMacro = !res.data.initial_macro_phase_date
          ? ""
          : res.data.initial_macro_phase_date.slice(0, 10);

        //Quantidade de delegados
        this.dateInitialEstadual = !res.data.initial_state_phase_date
          ? ""
          : res.data.initial_state_phase_date.slice(0, 10);
        this.proposalEstadual = !res.data.max_proposal_number_state_phase
          ? ""
          : res.data.max_proposal_number_state_phase;
        this.dateEndlEstadual11 = !res.data.final_state_phase_date
          ? ""
          : res.data.final_state_phase_date.slice(0, 10);
        this.formData.axie = res.data.axie;
        this.inputDelegate1 = res.data.state_stage_delegates_titular_number;
        this.inputDelegate = res.data.state_stage_delegates_substitutes_number;

        // Quantidade de municipio

        const arrayRules = res.data.rules;

        const allRules = arrayRules.map((name) => name.conference_types.name);
        const filteredType = [...new Set(allRules)];

        // numCountyDelegate
        // inputsCountyDelegates
        const filterMunicipal = filteredType.filter(
          (name) => name == "MUNICIPAL"
        );

        this.inputsCountyDelegates = arrayRules.filter(
          (x) => x.conference_types.name == filterMunicipal
        );
        this.numCountyDelegate = this.inputsCountyDelegates.length;

        const filterLivre = filteredType.filter((name) => name == "LIVRE");
        this.inputsInstituteDelegates = arrayRules.filter(
          (x) => x.conference_types.name == filterLivre
        );
        this.numInstituteDelegate = this.inputsInstituteDelegates.length;

        const filterMacro = filteredType.filter((name) => name == "MACRO");
        this.inputsRegionalNucleusDelegates = arrayRules.filter(
          (x) => x.conference_types.name == filterMacro
        );

        //inputsCountyDelegates
        /**
         * TODO - Uma das soluções que achei foi essa.
         * Definindo as datas minimas que podem ser alteradas.
         * Ref: https://vuetifyjs.com/en/components/date-pickers/#allowed-dates
         */
        // this.minStateBeginningDate = this.stateBeginningDate.split(" ")[0];
        // this.minStateFinishDate = this.stateFinishDate.split(" ")[0];

        // Mapeando 'Criação/Função'
        this.numCommissionFields = res.data.roles.length;
        this.commissionFields = res.data.roles.map(({ id, name }) => ({
          id,
          name,
        }));
        this.numInputs = res.data.axie.length;
        this.inputs = res.data.axie;
      });
    },

    submitForm() {
      // Pegando apenas os dados que são necessário para fazer o PUT
      this.formData.conference.title = this.title;
      this.formData.conference.conference_theme = this.theme;

      this.formData.conference.initial_state_phase_date =this.dateInitialEstadual;
      this.formData.conference.final_state_phase_date = this.dateEndlEstadual11;

      this.formData.conference.final_macro_phase_date = this.dateEndMacro;
      this.formData.conference.initial_macro_phase_date = this.dateInitialMacro;

      this.formData.conference.vigency = this.select;

      this.formData.roles = this.commissionFields;
      this.formData.conference.initial_county_phase_date =
        this.dateInitialMunicipal;
      this.formData.conference.final_county_phase_date = this.dateEndMunicipal;
      

      axios
        .put(`conference/${this.$route.query.id}`, this.formData)
        .then((res) => {
          this.message = res.data.message;
          this.snackbar = true;
          this.dialog = false;
          if (res.status !== 200) return;
          setTimeout(() => {
            setTimeout(() => {
              this.$router.push({ name: "Conferências" });
            });
          });
        })
        .catch((err) => {
          this.$swal({
            icon: "error",
            title: "Erro ao lançar propostas",
            text: err.data.details,
          });
          var error = "";
          Object.values(this.$store.state.errorMessage).map((value) => {
            error = value;
          });
          this.displayError2.message2 = error.toString();
          this.displayError2.display2 = true;
        });
    },
  },
};
</script>
<style>
.table-all {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.button-add {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: end;
}

.card-table-input-eixo {
  margin: 10px 30px;
}

.card-table {
  border: solid 1px blue;
  width: 500px;
  height: auto;
}

.card-table header {
  height: 0px !important;
}

.card-table-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: solid 1px green;
  height: 60px;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 75%,
    rgba(0, 212, 255, 1) 100%
  );
}

.space-button {
  margin-bottom: 20px;
}

.title-eixoss {
  width: 10px !important;
}

.card-table-header span {
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: white;
}

.region {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.label-macro input {
  width: 130px !important;
}

.sub-eixos-div {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.sub-eixos-div label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-eixos-div label div {
  width: 50px;
}

.m-5 {
  margin: 20px;
}

.input-adicional {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-adicional label {
  margin-right: 15px;
}

.input-adicional div {
  width: 200px;
}

.title-eixos {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  margin-top: -1.5%;
  margin-bottom: -30px;
}

.title-eixos label {
  margin-right: 5px;
}

.label-municipal {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.label-municipal > div {
  width: 250px;
}

.eixos-inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.grid-container-flex {
  display: flex;
  justify-content: start;
  align-items: start;
}

.eixos-eixos {
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
}

.eixos-news {
  width: 80% !important;
}

.eixos-inputs span {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 18px;
}

.eixos-inputs input {
  width: 250px;
  border-bottom: 1px solid #3123;
}

.eixos-inputs input:focus {
  outline: none;
}

.inputs-eixos {
  display: flex;
  flex-direction: column;
}

.inputs-eixos input:first-child {
  border-bottom: 1px solid #3123;
  width: 40px;
  margin-left: 10px;
}

.label-delegate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.label-delegate input {
  margin: 0 8px;
  width: 100px;
}

.grid-delegate {
  display: flex;
  flex-direction: row;
}

.division {
  margin-bottom: 40px;
}

.label-delegate input,
.label-municipal input {
  border-bottom: solid 1px #3123;
}

.column-flex-template {
  margin-right: 2%;
  width: 20%;
  display: flex;
  flex-direction: column;
}

.row-flex-template {
  display: flex;
  margin-bottom: -40px;
  flex-direction: row;
}
.row-flex-template-macro {
  margin-bottom: -40px;
}
.input-width {
  width: 5px;
  min-width: 2px !important;
}

.v-sheet--offset {
  top: -24px;
  position: relative;
}

.input-container {
  width: 100%;
}

.responsibility {
  margin-bottom: 20px;
  height: 28px;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 24px;
  text-align: center;
  white-space: nowrap;
  margin-top: 20px;
}

.responsibility-t {
  font-size: 18px;
  font-weight: 500;
}

.responsibility span {
  font-weight: 500;
  width: auto;
  margin-right: 25px;
}

.line-title {
  width: 100%;
  height: 2px;
  background-color: rgb(71, 68, 68);
}

.responsibility p {
  margin: 0;
}

.responsibility p span {
  font-weight: 500;
}

.table-button-modal {
  display: flex;
  justify-content: start;
  padding-left: 8px;
  margin-top: 5px;
  align-items: center;
  flex-direction: row;
}

.table-button-modal > button {
  margin-right: 10px;
  padding: 0px 7px;
  color: white;
  cursor: pointer;
  background-color: rgb(104, 101, 101);
  border-radius: 50%;
}

.main-municipality {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-top-margin {
  margin-top: 120px !important;
}

.title-span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
}

.title-span span {
  width: auto;
  margin-right: 15px;
}

.title-span hr {
  width: 100%;
  height: 2px;
}

.municipality {
  width: 100%;
  margin-top: 15px;
}

.municipality span {
  font-weight: 500;
}

.table-dashboard {
  width: 100%;
}

.card-chart {
  border: 1px solid salmon !important;
  border-radius: 8px !important;
}
</style>